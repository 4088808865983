import { supportedWallets } from '../constants/wallet';

export const getWalletExtensionStatus = () => {

  let extensions = {};
  // eslint-disable-next-line array-callback-return
  supportedWallets.map((walletInfo) => {
    let installed = false;
    if (window?.cardano && window?.cardano[walletInfo.key]) {
      installed = true;
    }
    extensions[walletInfo.key] = {
      name: walletInfo.name,
      installed,
      icon: walletInfo.icon,
    };
  });
  return extensions;
};

export const checkCanConnect = (walletKey = '') => {
  if (
    walletKey?.length > 0 &&
    window?.cardano &&
    window?.cardano[walletKey] &&
    typeof window.cardano[walletKey].enable == 'function'
  ) {
    return true;
  }
  return false;
};

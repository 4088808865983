import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

// import constant
import { supportedWallets } from '../constants';
import { checkCanConnect } from '../utils';

// import utils
import { getWalletExtensionStatus } from '../utils';

const WalletContext = createContext({
  isConnected: false,
  wallet: undefined,
  address: '',
  networkId: -1,
  connectedWalletKey: '',
  connect: async (v) => { return true},
  disconnect: () => {},
  adaBalance: '0',
  assets: {},
  walletExtensions: {},
  stakeAddress: undefined 
});

export const WalletContextProvider = ({ children } ) => {
  const [walletExtensions, setWalletExtension] = useState({});
  const [wallet, setWallet] = useState(undefined);
  const [address, setAddress] = useState('');
  const [stakeAddress, setStakeAddress] = useState('');
  const [networkId, setNetworkId] = useState(-1);
  const [adaBalance, setAdaBalance] = useState('0');
  const [assets, setAssets] = useState({});
  const [connectedWalletKey, setConnectedWalletKey] = useState('');

  // update network id, and address when wallet is changed
  useEffect(() => {
    if (wallet) {
      if (
        !(
          typeof wallet.getNetworkId == 'function' &&
          typeof wallet.getRewardAddresses == 'function' &&
          typeof wallet.getChangeAddress == 'function'
        )
      ) {
        toast.error('This wallet has an issue.');
        disconnect();
        return;
      }
      wallet
        ?.getNetworkId()
        .then((nId) => {
          if (parseInt(nId) >= 0) {
            setNetworkId(nId);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err?.message || "Couldn't get network Id.");
        });
      wallet
        ?.getRewardAddresses()
        .then((addresses) => {
          if (addresses && addresses?.length > 0) {
            setStakeAddress(addresses[0]);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err?.message || "Couldn't get stake address.");
        });
      wallet
        ?.getChangeAddress()
        .then((data) => {
          setAddress(data);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err?.message || "Couldn't get change address.");
        });
    }
  }, [wallet]);



  // methods
  const connect = async (walletKey) => {
    try {
      const foundWallet = supportedWallets.find(
        (e) => e.key == walletKey,
      );

      // check supported
      if (!foundWallet) {
        throw new Error('This wallet is not supported.');
      }
      console.log('foundwallet', walletKey, foundWallet)
      // check installed
      if (checkCanConnect(foundWallet.key)) {
        const connectedWallet = await window.cardano[walletKey].enable();
        if (connectedWallet) {
          setWallet(connectedWallet);
          setConnectedWalletKey(walletKey);
          window.localStorage.setItem(
            "savedWalletSelectionKey",
            walletKey,
          );
        }
        return true;
      } else {
        throw new Error('This Wallet extension not installed.');
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const disconnect = () => {
    setWallet(undefined);
    setConnectedWalletKey('');
    setStakeAddress('');
    setAddress('');
    setNetworkId(-1);
    setAdaBalance('0');
    setAssets({});
    window.localStorage.removeItem("savedWalletSelectionKey");
  };

  const init = () => {
    if (! connect) {
      return;
    }
    // init wallet extension info.
    setWalletExtension(getWalletExtensionStatus());

    // restore saved connected wallet.
    const savedConnectedWalletKey =
      window.localStorage.getItem("savedWalletSelectionKey") || '';
    if (savedConnectedWalletKey?.length > 0) {
      setConnectedWalletKey(savedConnectedWalletKey);
      connect(savedConnectedWalletKey).catch((err) => {
        toast.error(err?.message || `Couldn't connect to wallet`);
      });
    }
  };
                                                                           
  useEffect(() => {
    if (!init) {
      return;
    }
    console.log('Wallet Init...');
    init();
    return () => {};
  }, []);

  const value = {
    isConnected: wallet ? true : false,
    walletExtensions,
    wallet,
    address,
    networkId,
    stakeAddress,
    connectedWalletKey,
    connect,
    disconnect,
    assets,
    adaBalance,
  };

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
    // <>adfasdf</>
  );
};

export const useWallet = () => {
  return useContext(WalletContext);
};


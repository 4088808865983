import { useState } from "react";

const Detail = (props) => {
  const [data, setData] = useState(props.data);
  return (
    <div className='cards-section dark-bg'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='color-purple'>{data.title}</h2>
          </div>
        </div>
        <div className='row gx-3 gy-3'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card border-none'>
              <p>{data.p1}</p>
              <p>{data.p2}</p>
              <div className='logo-section'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-12 text-center'>
                      <img
                        className=' img-fluid rounded'
                        src={data.imgSrc}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p>{data.p3}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;

import { useRef } from "react";
import "./css/raven.css";
import { Toaster, toast } from "react-hot-toast";
const Raven = () => {
  const walletAddressRef = useRef(null);
  const walletAddress =
    "addr1v9xml6kyxe3hqs53md7qn0rdqywxykudwu7qpy8mmh00q3q3y6zje";
  const length = walletAddress.length;
  const midpoint = Math.floor(length / 2);

  const firstHalfWalletAddr = walletAddress.slice(0, midpoint);
  const secondHalfWalletAddr = walletAddress.slice(midpoint);

  const copyText = () => {
    try {
      let text = document.getElementById("holdtext").innerText;
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      let status = document.execCommand("Copy"); // Remove the temporary textarea
      document.body.removeChild(textarea);
      if (!status) {
        console.log("Cannot copy text");
      } else {
        toast.success("Wallet Address Copied");
      }
    } catch (err) {
      // toastr.error('Could not copy');
      // handle error
      console.log(err);
    }
  };
  return (
    <div className='App'>
      <Toaster
        containerStyle={{ fontSize: "12px" }}
        position='bottom-right'
        reverseOrder={false}
      />
      <header className='raven-app-header'>
        {/* <button className="connect-button"> */}
        <a
          href='https://wyrd.digital/wyrdpaper.pdf'
          className='link-white'
        >
          WYRDPAPER
        </a>
        <div className='btn btn-outline-primary btn-md connect-button'>
          CONNECT
        </div>
        <a
          href='https://twitter.com/wyrddigital'
          className='social-link link-x'
        >
          <img
            src='x-logo.png'
            alt='discord'
          />
        </a>
        <a
          href='https://discord.gg/XshZD3APx5'
          className='social-link link-discord'
        >
          <img
            src='./wdiscord.png'
            alt='discord'
          />
        </a>

        {/* MAIN LOGO */}
        <img
          src='./wyrdraven.png'
          className='raven-app-logo p-1 mb-5'
          alt='logo'
        />

        <p className='mt-3 px-3'>
          To receive you new <b>RAVEN NFT</b> you must take <b>ONE</b>{" "}
          transaction that includes both:
        </p>
        <p className='text-color'>
          5 ADA & A VALKRY DECKS NFT OR A VALKRY FOUNDERS PASS
        </p>
        {/* <p className='text-gold px-3'>
          A VALKRY DECKS NFT or VALKRY FOUNDERS PASS
        </p> */}
        <p className=' px-3'>
          Send a <b>SINGLE</b> transaction containing both to:
        </p>
        <p className='text-gold px-3'>
          <span
            id='holdtext'
            ref={walletAddressRef}
            onClick={copyText}
          >
            {/* <div>{walletAddress}</div> */}
            <div>{firstHalfWalletAddr}</div>
            <div>{secondHalfWalletAddr}</div>
          </span>
          <span
            className='px-2 cursor-pointer'
            onClick={copyText}
          >
            {" "}
            Copy Wallet Address{" "}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fill-rule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </span>
        </p>

        <p className='mb-5 px-3'>
          NOTE: Your PARK DEED NFT will be airdropped within 72 hours of
          redemption.
        </p>
      </header>
    </div>
  );
};

export default Raven;

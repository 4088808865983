import eternlIcon from '../assets/wallets/eternl.png';
import flintIcon from '../assets/wallets/flint.svg';
import namiIcon from '../assets/wallets/nami.svg';
import typhonIcon from '../assets/wallets/typhon.svg';

export const supportedWallets = [
  { name: 'Nami Wallet', key: 'nami', icon: namiIcon },
  { name: 'Eternl Wallet', key: 'eternl', icon: eternlIcon },
  { name: 'Typhon', key: 'typhoncip30', icon: typhonIcon },
  { name: 'Flint', key: 'flint', icon: flintIcon },
];

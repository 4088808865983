import "./App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./components/home";
import Raven from "./components/raven";
import Mongrel from "./components/mongrel";
import Digital from "./components/digital/digital";
import Layout from "./components/digital/layout";
import Brand from "./components/digital/wyrdDigital/brand";
import OurMission from "./components/digital/wyrdDigital/ourMission";
import Technology from "./components/digital/wyrdDigital/technology";
import Contact from "./components/digital/wyrdDigital/contact";
import Park from "./components/digital/projects/park";
import OssuMarket from "./components/digital/projects/ossuMarket";
import IkuToken from "./components/digital/projects/ikuToken";
import ValkryRaven from "./components/digital/projects/valkryRaven";
import ParkRat from "./components/digital/nft-collection/park-rats";
import Mongrels from "./components/digital/nft-collection/mongrels";
import ParkDeed from "./components/digital/nft-collection/park-deed";
import Ravens from "./components/digital/nft-collection/ravens";
import HappyHopper from "./components/digital/nft-collection/happy-hopper";
import Connect from "./components/connect";

function App() {
  // return <Home />;
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          exact
          element={<Home />}
        />
        <Route
          path='/raven'
          exact
          element={<Raven />}
        />

        <Route
          path='/mongrels'
          exact
          element={<Mongrel />}
        />
        <Route element={<Layout />}>
          <Route
            path='/connect'
            exact
            element={<Connect />}
          />
          <Route
            path='/home'
            exact
            element={<Digital />}
          />
          <Route
            path='/brand'
            exact
            element={<Brand />}
          />

          <Route
            path='/our-mission'
            exact
            element={<OurMission />}
          />
          <Route
            path='/technology'
            exact
            element={<Technology />}
          />
          <Route
            path='/the-team'
            exact
            element={<Contact />}
          />
          <Route
            path='/the-park'
            exact
            element={<Park />}
          />

          <Route
            path='/ossu-market'
            exact
            element={<OssuMarket />}
          />
          <Route
            path='/iku-token'
            exact
            element={<IkuToken />}
          />
          <Route
            path='/valkry-raven'
            exact
            element={<ValkryRaven />}
          />

          <Route
            path='/park-rats'
            exact
            element={<ParkRat />}
          />
          <Route
            path='/mongrel'
            exact
            element={<Mongrels />}
          />
          <Route
            path='/raven-deeds'
            exact
            element={<ParkDeed />}
          />
          <Route
            path='/ravens'
            exact
            element={<Ravens />}
          />
          <Route
            path='/happy-hoppers'
            exact
            element={<HappyHopper />}
          />
        </Route>
        <Route
          path='*'
          element={<Navigate to='/' />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

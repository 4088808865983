import { Buffer } from 'buffer';
import * as L from 'lucid-cardano';

export const fromHex = (hex) => Buffer.from(hex, 'hex');
export const toHex = (bytes) => Buffer.from(bytes).toString('hex');
export const toUTF8 = (hex) => Buffer.from(hex, 'hex').toString('utf8');
export const UTF8toHex = (utf8) => Buffer.from(utf8, 'utf8').toString('hex');

export const convertHexAddressToBech32 = (stakeHexAddress = '') => {
  try {
    if (!(stakeHexAddress?.length > 0)) {
      return '';
    }
    const bech32StakeAddress = L.C.Address.from_bytes(
      L.fromHex(stakeHexAddress),
    ).to_bech32();
    return bech32StakeAddress;
  } catch (_) {
    try {
      const bech32StakeAddress =
        L.C.Address.from_bech32(stakeHexAddress).to_bech32();
      return bech32StakeAddress;
    } catch (_) {
      return '';
    }
  }
};

export const checkTraitHexStringAndConvertToUtf8 = (
  text = '',
  type = 'CIP68',
) => {
  const reg = /[0-9A-Fa-f]{2}/g;
  const matchLength = text.match(reg)?.length || 0;
  if (text.length / 2 - matchLength == 0) {
    const utf8String = Buffer.from(text, 'hex').toString();
    if (type == 'CIP68') {
      return utf8String.slice(1);
    } else {
      return utf8String;
    }
  } else {
    return text;
  }
};

export const simplifyTxHash = (txHash = '') => {
  return txHash.slice(0, 6) + '...' + txHash.slice(-6);
};

export const simplifyStakeAddress = (stakeAddress = '') => {
  return stakeAddress.slice(0, 6) + '...' + stakeAddress.slice(-6);
};

export const simplifyPolicyId = (policyId = '') => {
  return policyId.slice(0, 8) + '...' + policyId.slice(-6);
};

export const saveNFTMetadataToCache = ({ metadata, assetId }) => {
  if (!window.nftMetadataCache) {
    window.nftMetadataCache = {};
  }
  window.nftMetadataCache[assetId] = metadata;
};

export const extractTraitsFromMetadata = ({ metadata }) => {
  const onChainMetadata = metadata?.onchain_metadata || {};
  const metadataStandard = metadata?.onchain_metadata_standard || '';
  const excludedKeys = ['name', 'files', 'image', 'mediaType'];

  let traits = {};

  if (metadataStandard.includes('CIP25')) {
    for (const [key, value] of Object.entries(onChainMetadata)) {
      const isExcluded = excludedKeys.findIndex((e) => e == key) >= 0;
      if (!isExcluded && typeof value != 'object') {
        traits[key] =
          typeof value == 'string'
            ? checkTraitHexStringAndConvertToUtf8(value, 'CIP25')
            : value;
      }
    }
  } else if (metadataStandard.includes('CIP68')) {
    for (const [key, value] of Object.entries(onChainMetadata)) {
      const isExcluded = excludedKeys.findIndex((e) => e == key) >= 0;
      if (!isExcluded && typeof value != 'object') {
        traits[key] =
          typeof value == 'string'
            ? checkTraitHexStringAndConvertToUtf8(value, 'CIP68')
            : value;
      }
    }
  }
  return traits;
};

export const printErrorMessage = (message = '', error) => {
  console.error(message);
  console.error(error);
  console.error('-'.repeat(40));
};

export const convertDiscordAvatarUrlToHash = (url = '') => {
  try {
    const data = url.split('/');
    if (data.length == 6) {
      const hash = data[5].split('.')[0];
      return hash || '';
    } else {
      return '';
    }
  } catch (err) {
    console.error(err);
    return '';
  }
};

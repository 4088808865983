import Detail from "../detail";

const Mongrels = () => {
  const data = {
    title: "Mongrels",
    p1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchafive   centuries, but also the leap into electronic typesetting, remaining essentially uncha",
    p2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchafive   centuries, but also the leap into electronic typesetting, remaining essentially uncha",
    imgSrc: "dumy-image.png",
    p3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchafive   centuries, but also the leap into electronic typesetting, remaining essentially uncha",
  };

  return <Detail data={data} />;
};

export default Mongrels;

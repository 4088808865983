import { Link, useNavigate } from "react-router-dom";
import { useWallet } from "../contexts";
import { useEffect, useState } from "react";
import { convertHexAddressToBech32 } from "../utils";
const Home = () => {
  const navigate = useNavigate();
  const { connect, disconnect, isConnected, wallet } = useWallet()
  const [ walletAdr, setWalletAdr ] = useState(undefined)

  // const handleNavigateClick = (url) => {
  //   // Navigate to a different URL upon button click
  //   navigate(url);
  // };

  const handleClickConnectBtn = () => {
    console.log('handle connect')
    connect('nami')
  }
  const handleClickDisConnectBtn = () => {
    disconnect('nami')
  }

  const getChangeAddress = async () => {
    setWalletAdr( convertHexAddressToBech32(await wallet.getChangeAddress()))
  }

  useEffect(()=>{
    console.log('isconnecte', isConnected)
    if(isConnected) {
      getChangeAddress()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isConnected, wallet])

  return (
    <div className='App'>
      <header className='App-header'>
        {/* <button className="connect-button"> */}
        <a
          href='https://wyrd.digital/wyrdpaper.pdf'
          className='link-white'
        >
          WYRDPAPER
        </a>
        <div
          className='btn btn-outline-primary btn-md connect-button'
          onClick={ isConnected ? handleClickDisConnectBtn : handleClickConnectBtn}
        >
          {isConnected ? 'DISCONNECT' :'CONNECT'}
        </div>
        <a
          href='https://twitter.com/wyrddigital'
          className='social-link link-x'
        >
          <img
            src='x-logo.png'
            alt='discord'
          />
        </a>
        <a
          href='https://discord.gg/XshZD3APx5'
          className='social-link link-discord'
        >
          <img
            src='./wdiscord.png'
            alt='discord'
          />
        </a>

        {/* MAIN LOGO */}
        <img
          src='./wyrd.png'
          className='App-logo'
          alt='logo'
        />

        <p className='mt-3 p-2'>HOME OF THE RAVENS, MONGRELS, AND PARK RATS</p>
        {
          walletAdr && 
          <p className='mt-3 p-2'>{`You are connected to Wallet: ${walletAdr}`}</p>
        }

      </header>
    </div>
  );
};

export default Home;

import { useRef } from "react";
import "./css/raven.css";
import { Toaster, toast } from "react-hot-toast";
const Mongrel = () => {
  const walletAddressRef = useRef(null);
  const walletAddress =
    "addr1qxfh62xfv875j3k5d9qz6lm77qswfnmww0se4d623pfweplhddvr866fs7j3lwyhfgzvsr9xa7xumn4uyuv6l89kdrfs4qhp5z";

  const length = walletAddress.length;
  const partLength = Math.floor(length / 3);

  const firstPart = walletAddress.slice(0, partLength);
  const secondPart = walletAddress.slice(partLength, 2 * partLength);
  const thirdPart = walletAddress.slice(2 * partLength);
  const copyText = () => {
    try {
      let text = document.getElementById("holdtext").innerText;
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      let status = document.execCommand("Copy"); // Remove the temporary textarea
      document.body.removeChild(textarea);
      if (!status) {
        console.log("Cannot copy text");
      } else {
        toast.success("Wallet Address Copied");
      }
    } catch (err) {
      // toastr.error('Could not copy');
      // handle error
      console.log(err);
    }
  };
  return (
    <div className='App'>
      <Toaster
        containerStyle={{ fontSize: "12px" }}
        position='bottom-right'
        reverseOrder={false}
      />
      <header className='raven-app-header'>
        {/* <button className="connect-button"> */}
        <a
          href='https://wyrd.digital/wyrdpaper.pdf'
          className='link-white'
        >
          WYRDPAPER
        </a>
        <div className='btn btn-outline-primary btn-md connect-button'>
          CONNECT
        </div>
        <a
          href='https://twitter.com/wyrddigital'
          className='social-link link-x'
        >
          <img
            src='x-logo.png'
            alt='discord'
          />
        </a>
        <a
          href='https://discord.gg/XshZD3APx5'
          className='social-link link-discord'
        >
          <img
            src='./wdiscord.png'
            alt='discord'
          />
        </a>

        {/* MAIN LOGO */}
        {/* <img
          src='./wyrdraven.png'
          className='raven-app-logo p-1 mb-5'
          alt='logo'
        /> */}

        <div className='p-3'>
          <h1 className='mt-29 mb-5 text-gold'>MONGRELS V2 UPGRADE</h1>
          <p className='px-3'>
            To receive your new MONGRELS V2 NFT please do the following:
          </p>
          <p className='text-color'>
            Send 1-5 Mongrels NFTs along with 5 ADA to the following address:
          </p>
          {/* <p className='text-gold px-3'>
          A VALKRY DECKS NFT or VALKRY FOUNDERS PASS
        </p> */}
          <p className=' px-3'>
            Send a <b>SINGLE</b> transaction containing all assets to:
          </p>
          <p className='text-gold px-3 '>
            <span
              id='holdtext'
              ref={walletAddressRef}
              onClick={copyText}
            >
              <div className='hide-moble-div'> {walletAddress}</div>

              <div className='mobile-show'>
                <div>{firstPart}</div>
                <div>{secondPart}</div>
                <div>{thirdPart}</div>
              </div>
            </span>
            <span
              className='px-2 cursor-pointer'
              onClick={copyText}
            >
              Copy Wallet Address{" "}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-copy'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
                />
              </svg>
            </span>
          </p>
        </div>

        {/* <p className='mb-5 px-3'>
          NOTE: Your PARK DEED NFT will be airdropped within 72 hours of
          redemption.
        </p> */}
      </header>
    </div>
  );
};

export default Mongrel;

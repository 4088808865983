import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const Layout = () => {
  const navigate = useNavigate();

  const [hideHeaderlogo, setHideHeaderLogo] = useState(false);

  const [connectLink, setConnectLink] = useState(false);
  const location = useLocation();
  const [urlLink, setUrlLink] = useState(window.location.pathname);

  // navbar handling useEffect
  useEffect(() => {
    const div = document.getElementById("NavBar-side");
    const addBtn = document.getElementById("add-btn");
    const removeBtn = document.getElementById("remove-btn");

    const handleAddClick = () => {
      div.classList.add("NavBar-side");
    };

    const handleRemoveClick = () => {
      div.classList.remove("NavBar-side");
    };

    addBtn.addEventListener("click", handleAddClick);
    removeBtn.addEventListener("click", handleRemoveClick);

    return () => {
      addBtn.removeEventListener("click", handleAddClick);
      removeBtn.removeEventListener("click", handleRemoveClick);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when route changes
    console.log("location.pathname -> ", location.pathname);
    const pathName = location.pathname;
    if (pathName === "/") {
      setHideHeaderLogo(false);
    } else setHideHeaderLogo(true);

    setConnectLink(pathName === "/connect" ? true : false);
    setUrlLink(pathName);
  }, [location.pathname]); // Run this effect whenever the pathname changes

  const handleRemoveNavbarClick = () => {
    const div = document.getElementById("NavBar-side");
    div.classList.remove("NavBar-side");
  };

  const handleNavigateClick = (url) => {
    // Navigate to a different URL upon button click
    navigate(url);
  };

  return (
    <section>
      <div className='App'>
        <header className='top-nav'>
          <button
            id='add-btn'
            type='button'
            className='btn menu-icon'
          >
            <svg
              version='1.1'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              x='0px'
              y='0px'
              viewBox='0 0 448 512'
              style={{ enableBackground: "new 0 0 448 512" }}
              xmlSpace='preserve'
            >
              <path
                className='st0'
                d='M-62,96c0-17.7,14.3-32,32-32h444c17.7,0,32,14.3,32,32s-14.3,32-32,32H-30C-47.7,128-62,113.7-62,96z M-62,256
    c0-17.7,14.3-32,32-32h444c17.7,0,32,14.3,32,32s-14.3,32-32,32H-30C-47.7,288-62,273.7-62,256z M446,416c0,17.7-14.3,32-32,32H-30
    c-17.7,0-32-14.3-32-32s14.3-32,32-32h444C431.7,384,446,398.3,446,416z'
              ></path>
            </svg>
          </button>
          {/* <button className="connect-button"> */}
          {/* <a
            href='https://wyrd.digital/wyrdpaper.pdf'
            className='link-white'
          >
            WYRDPAPER
          </a> */}
          <div
            className='btn btn-outline-primary btn-md connect-button'
            onClick={() => handleNavigateClick("/connect")}
          >
            CONNECT
          </div>
          <a
            href='https://twitter.com/wyrddigital'
            className='social-link link-x-digital'
          >
            <img
              src='x-logo.png'
              alt='x-logo'
            />
          </a>
          <a
            href='https://discord.gg/XshZD3APx5'
            className='social-link link-discord-digital'
          >
            <img
              src='./wdiscord.png'
              alt='discord-logo'
            />
          </a>
        </header>
      </div>

      <div className='page-wrapper'>
        {hideHeaderlogo && (
          <div className='logo-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <img
                    className='logo-section-img'
                    src='wyrd.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Layout pages renders here */}
        <main className='content'>
          <Outlet />
        </main>
        <div className='Copyright'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 text-center'>
                Copyright 2023, 2024. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='NavBar'>
        <div className='ad-logo'>
          <Link to='/'>
            <img
              src='wyrd-l.png'
              alt=''
              onClick={handleRemoveNavbarClick}
            />
          </Link>
        </div>

        <div className='leftnav'>
          <button
            id='remove-btn'
            className='btn-close'
          ></button>

          {connectLink && <div className='leftnav-title'>My Account</div>}
          {connectLink && (
            <ul>
              <li>
                <Link
                  className={`${
                    urlLink === "/#" ? "left-nave-link-active" : ""
                  }`}
                  to='/home'
                  onClick={handleRemoveNavbarClick}
                >
                  MY ACCOUNT
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    urlLink === "/#" ? "left-nave-link-active" : ""
                  }`}
                  to='#'
                  onClick={handleRemoveNavbarClick}
                >
                  MY NFTS
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    urlLink === "/#" ? "left-nave-link-active" : ""
                  }`}
                  to='#'
                  onClick={handleRemoveNavbarClick}
                >
                  MINTING
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    urlLink === "/#" ? "left-nave-link-active" : ""
                  }`}
                  to='#'
                  onClick={handleRemoveNavbarClick}
                >
                  CLAIMS{" "}
                </Link>
              </li>
            </ul>
          )}
          <div className='leftnav-title'>wyrd.digital</div>
          <ul>
            <li>
              <Link
                className={`${
                  urlLink === "/brand" ? "left-nave-link-active" : ""
                }`}
                to='/brand'
                onClick={handleRemoveNavbarClick}
              >
                THE BRAND
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/our-mission" ? "left-nave-link-active" : ""
                }`}
                to='/our-mission'
                onClick={handleRemoveNavbarClick}
              >
                OUR MISSION
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/technology" ? "left-nave-link-active" : ""
                }`}
                to='/technology'
                onClick={handleRemoveNavbarClick}
              >
                TECHNOLOGY
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/the-team" ? "left-nave-link-active" : ""
                }`}
                to='/the-team'
                onClick={handleRemoveNavbarClick}
              >
                THE TEAM{" "}
              </Link>
            </li>
          </ul>
          <div className='leftnav-title'>projects</div>
          <ul>
            <li>
              <Link
                className={`${
                  urlLink === "/the-park" ? "left-nave-link-active" : ""
                }`}
                to='/the-park'
                onClick={handleRemoveNavbarClick}
              >
                THE PARK
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/ossu-market" ? "left-nave-link-active" : ""
                }`}
                to='/ossu-market'
                onClick={handleRemoveNavbarClick}
              >
                OSSU MARKET
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/iku-token" ? "left-nave-link-active" : ""
                }`}
                to='/iku-token'
                onClick={handleRemoveNavbarClick}
              >
                $IKU TOKEN
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/valkry-raven" ? "left-nave-link-active" : ""
                }`}
                to='/valkry-raven'
                onClick={handleRemoveNavbarClick}
              >
                VALKRY/RAVN{" "}
              </Link>
            </li>
          </ul>
          <div className='leftnav-title'>nft collections</div>
          <ul>
            <li>
              <Link
                className={`${
                  urlLink === "/park-rats" ? "left-nave-link-active" : ""
                }`}
                to='/park-rats'
                onClick={handleRemoveNavbarClick}
              >
                PARK RATS
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/mongrel" ? "left-nave-link-active" : ""
                }`}
                to='/mongrel'
                onClick={handleRemoveNavbarClick}
              >
                MONGRELS
              </Link>
            </li>
            <li>
              <Link
                className={`${
                  urlLink === "/raven-deeds" ? "left-nave-link-active" : ""
                }`}
                to='/raven-deeds'
                onClick={handleRemoveNavbarClick}
              >
                RAVENS / DEEDS
              </Link>
            </li>

            <li>
              <Link
                className={`${
                  urlLink === "/happy-hoppers" ? "left-nave-link-active" : ""
                }`}
                to='/happy-hoppers'
                onClick={handleRemoveNavbarClick}
              >
                HAPPY HOPPERS
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Layout;

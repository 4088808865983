import React, { useEffect } from "react";
import "./digital.css";
import { Link, useNavigate } from "react-router-dom";

const Digital = () => {
  const navigate = useNavigate();

  const handleNavigateClick = (url) => {
    // Navigate to a different URL upon button click
    navigate(url);
  };
  return (
    <section>
      <div className='cards-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2>wyrd.digital</h2>
            </div>
          </div>
          <div className='row gx-3 gy-3'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  brand
                </h3>

                <p>
                  Wyrd Digital is a pioneering blockchain IP company dedicated
                  to crafting innovative products in conjunction with NFT
                  assets. Our primary focus, which we share with our passionate
                  community, is spread across gaming, streetwear, and art
                  through a cross-chain development strategy.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/brand")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  mission
                </h3>
                <p>
                  Our mission is to harness the power of blockchain technology
                  to integrate Web3 and business innovation with organic
                  community growth while driving sustainable product
                  development, deployment, and adoption. Creating methods for
                  traditional companies to engage web3 is a primary objective.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/our-mission")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  technology
                </h3>
                <p>
                  Wyrd Digital is driven by the transformative potential of
                  decentralized governance and financial systems, NFT
                  technology, and advancements in AI. These technologies not
                  only shape our approach but also empower us to craft impactful
                  products and experiences.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/technology")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  team
                </h3>
                <p>
                  The Wyrd Digital team brings together extensive experience in
                  business development, marketing, and operations coupled with
                  over a decade of expertise in blockchain technology. We are
                  consistantly seeking top tier talent and adding valuable new
                  team members to the Wyrd Digital family.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/the-team")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='cards-section dark-bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2>projects</h2>
            </div>
          </div>
          <div className='row gx-3 gy-3'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  the park
                </h3>
                <p>
                  Our upcoming gaming platform combines interactive gaming
                  experiences with community driven features. Here you can find
                  leaderboards, gaming reviews, the opportunity to test
                  beta-games alongside game developers and more. First iteration
                  to ship by end of Q1 2024.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/the-park")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  ossu market
                </h3>
                <p>
                  Our digital book and collectibles platform, fueled by $IKU
                  token, operates as both a publisher and curator. Through
                  licensing agreements and open domain IPs, we will deliver
                  premium content and collectibles, offering a diverse range of
                  top-tier experiences. Platform to ship by EOY 2024.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/ossu-market")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  valkry / ravn
                </h3>
                <p>
                  RAVN is our dynamic, accessible, high quality lifestyle brand,
                  with a strong emphasis on the contemporary streetwear market.
                  RAVN is also the parent brand of Valkry, our limited run,
                  premium products, available only to specified holders of early
                  Wyrd Digital nft assets.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/iku-token")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='cards-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2>nft collections</h2>
            </div>
          </div>
          <div className='row gx-3 gy-3'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  park rats
                </h3>
                <p>
                  Park Rats stand as our flagship NFT IP. They will be
                  synonymous with the Wyrd brand representing innovation,
                  creativity, and quality. This anime art collection, by Rendr,
                  will drop on Polygon in Spring 2024 and will unify our
                  community as our most popular PFP option.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/park-rats")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  mongrels
                </h3>
                <p>
                  Our innovative NFT collection has served as a gateway to
                  community and a suite of unique experiences. Through a
                  phygital approach, select traits within the collection unlock
                  physical collector items. Mongrels provide access to events,
                  giveaways, gaming tournaments, and more.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/mongrel")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  ravens / deeds
                </h3>
                <p>
                  Ravens, our NFT flagship collection, are designated for our
                  earliest supporters and receive premium treatment. Serving as
                  profile pictures for these valued members, Ravens come with
                  exclusive benefits and privileges, rewarding their ownership
                  with unique perks and recognition.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/raven-deeds")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='card'>
                <h3>
                  <img
                    src='wyrd-l.png'
                    alt=''
                  />{" "}
                  happy hoppers
                </h3>
                <p>
                  Our most recent acquisition, chosen for its potential in
                  merchandising and mobile gaming, includes a mobile game set to
                  launch in summer 2024 alongside the development of 3D avatars
                  and prototype toys. We're thrilled to bring this beloved NFT
                  IP to life in engaging ways.
                </p>
                <button
                  className='btn btn-details'
                  onClick={() => handleNavigateClick("/ravens")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-caret-right-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Digital;

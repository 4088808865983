import { useSearchParams, useNavigate } from "react-router-dom";

const MONGREL = "mongrel";
const RAVENS = "ravens";
const PARK_DEEDS = "park-deeds";
const PARK_RATS = "park-rats";
const HAPPY_HOPPPERS = "happy-hoppers";

const Connect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const paramValue = searchParams.get("page");

  const handleNavigateClick = (url) => {
    // Navigate to a different URL upon button click
    navigate(url);
  };

  const handleClick = (param) => {
    // Add query parameters to the URL
    setSearchParams({ page: param });
  };
  return (
    <div className='App'>
      <div className='set-padding'>
        <button
          className={`'btn btn-primary btn-home ' ${
            paramValue === MONGREL ? "btn-active" : ""
          }`}
          onClick={() => handleClick(MONGREL)}
        >
          <img
            src='./wyrd-l.png'
            alt='icon'
          />{" "}
          Mongrels
        </button>
        <button
          className={`'btn btn-primary btn-home ' ${
            paramValue === RAVENS ? "btn-active" : ""
          }`}
          onClick={() => handleClick(RAVENS)}
        >
          <img
            src='./wyrd-l.png'
            alt='icon'
          />{" "}
          Ravens
        </button>
        <button
          className={`'btn btn-primary btn-home ' ${
            paramValue === PARK_DEEDS ? "btn-active" : ""
          }`}
          onClick={() => handleClick(PARK_DEEDS)}
        >
          <img
            src='./wyrd-l.png'
            alt='icon'
          />{" "}
          Park Deeds
        </button>
        <button
          className={`'btn btn-primary btn-home ' ${
            paramValue === PARK_RATS ? "btn-active" : ""
          }`}
          onClick={() => handleClick(PARK_RATS)}
        >
          <img
            src='./wyrd-l.png'
            alt='icon'
          />{" "}
          Park Rats
        </button>
        <button
          className={`'btn btn-primary btn-home ' ${
            paramValue === HAPPY_HOPPPERS ? "btn-active" : ""
          }`}
          onClick={() => handleClick(HAPPY_HOPPPERS)}
        >
          <img
            src='./wyrd-l.png'
            alt='icon'
          />{" "}
          Happy Hoppers
        </button>
      </div>

      <div className='container'>
        {!paramValue && (
          <div className='row'>
            <div className='col-12 bodyfont'>
              <h2 className='heading02'>updates</h2>
              <p className='border-left'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchafive
                centuries, but also the leap into electronic typesetting,
                remaining essentially
              </p>
              <p className='border-left'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchafive
                centuries, but also the leap into electronic typesetting,
                remaining essentially uncha
              </p>
            </div>
          </div>
        )}

        {paramValue === MONGREL && (
          <div className='row gx-3 gy-3'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158 Mongrel #0158 Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='infocard'>
                <img
                  src='dumy-image-2.png'
                  className='card-img-top'
                  alt='...'
                />
                <h3>Mongrel #0158</h3>
                <div className='row gx-3'>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      STAKE
                    </button>
                  </div>
                  <div className='col-6'>
                    <button
                      className='btn btn-outline-primary'
                      type='button'
                    >
                      HARVEST
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Connect;
